import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Tags from "../../../tags/Tags";
import "./TagsCloud.scss"

const MAXIMUM_TAGS_LENGTH = 50

const TagsCloud = () => {
    const { allMarkdownRemark: data } = useStaticQuery(
        graphql`
            query {
                allMarkdownRemark {
                    edges {
                        node {
                            frontmatter {
                                tags
                            }
                        }
                    }
                }
            }
        `
    )

    const tags = [...new Set(data?.edges.map(e => e.node.frontmatter.tags).flat())] || []
    if(tags.length > MAXIMUM_TAGS_LENGTH) {
        tags.length = MAXIMUM_TAGS_LENGTH;
    }

    return (
        <div className="aside_widget aside_widget-tags_cloud">
            <div className="aside_widget-title">
                <h3>Aquí hablamos de</h3>
            </div>
            <div className="aside_widget-tags_cloud-container">
                <Tags tags={ tags } />
                <div className="even-more">... y de mucho más.</div>
            </div>
        </div>
    )
}

export default TagsCloud
