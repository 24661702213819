import * as React from "react"
import SocialLinksList from "../../../socialLinks/SocialLinksList";
import "./SocialNetworksWidget.scss"

const SocialNetworksWidget = () => {
    return (
        <div className="aside_widget aside_widget-ssnn">
            <div className="aside_widget-title">
                <h3>Encuéntranos en</h3>
            </div>
            <div className="aside_widget-ssnn-container">
                <SocialLinksList />
            </div>
        </div>
    )
}

export default SocialNetworksWidget
