import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"

const Image = ({ fileName, alt, title, className, width, height}) => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    images: allFile {
                        edges {
                            node {
                                relativePath
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={ data => {
                const image = data.images.edges.find(e => e.node.relativePath.includes(fileName))
                return image ? <img
                    src={ image.node.childImageSharp.fluid.src }
                    srcSet={ image.node.childImageSharp.fluid.srcSet }
                    sizes={ image.node.childImageSharp.fluid.sizes }
                    alt={ alt }
                    title={ title }
                    width={ width }
                    height={ height }
                    className={ className }
                /> : null
            } }
        />
    )
}

Image.propTypes = {
    fileName: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired
}


export default Image
