module.exports = {
    meta: {
        title: `htbwriteups.com`,
        description: `Los mejores writeups de tus máquinas favoritas de HackTheBox. Busca lo que necesites y aprende aquello que te falte para potenciar tu lado Hacky`,
        author: `@okboomersh`,
        siteUrl: `https://htbwriteups.com/`,
        locale: 'es_ES',
        lang: 'es',
        sitemap: {
            disallow: [
                '/aviso-legal',
                '/politica-de-cookies',
                '/politica-de-privacidad'
            ]
        },
        gtagUACode : 'G-H6GMQ7MLVY'
    },
    social: [
        {name: 'twitter', href: 'https://twitter.com/okboomersh', icon: 'entypo-social:twitter-with-circle'},
        {name: 'rss', href: 'https://htbwriteups.com/rss.xml', icon: 'fa-solid:rss-square'},
    ],
    nav: [
        {
            href: '/',
            title: 'Home',
            anchor: 'Home',
            description: 'Descripción inicio'
        },
        {
            href: '/writeups',
            title: 'Writeups',
            anchor: 'Writeups',
            description: 'En este categoría vas a encontrar los writeups de los diferentes retos propuestos por HackTheBox ya resueltos. Espero que te sea de utilidad.'
        }
    ]
}
