import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import SocialLinksList from "../socialLinks/SocialLinksList";
import Image from "../image/Image";
import "./Footer.scss"

const Footer = () => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `
    )

    const siteTitle = site.siteMetadata?.title || ''

    return (
        <footer>
            <div className="pre-footer"></div>
            <div className="footer-content">
                <div className="footer-credits">
                    <div className="footer-widget">
                        <Link to='/' title="Home">
                            <Image
                                className="logo"
                                title={ siteTitle }
                                alt={ siteTitle }
                                fileName="logo.png"
                                width="319"
                                height="84"
                            />
                        </Link>
                    </div>
                    <div className="footer-social-networks">
                        <SocialLinksList />
                    </div>
                    <div className="footer-widget footer-copyright">Copyright © 2022 { siteTitle }</div>
                    <div className="footer-widget footer-legal-stuff">
                        <ul>
                            <li><Link to='/aviso-legal' title="Aviso legal" rel="nofollow">Aviso legal</Link></li>
                            <li><Link to='/politica-de-cookies' title="Política de cookies" rel="nofollow">Política de
                                cookies</Link></li>
                            <li><Link to='/politica-de-privacidad' title="Política de privacidad" rel="nofollow">Política
                                de privacidad</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
