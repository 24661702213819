import * as React from 'react'
import { Link } from 'gatsby'
import { Icon } from '@iconify/react';
import config from "../../config/config"
import './Navigation.scss'

const Navigation = ({ activeItem }) => {
    const handleClick = (e) => {
        e.preventDefault()
        const x = document.getElementById("myTopnav");
        x.className === "topnav" ? x.className += " responsive" : x.className = "topnav"
    }

    return (
        <div className="topnav" id="myTopnav">
            <nav>
                {
                    config.nav.map((e, idx) => {
                        let nameClass = ''
                        if(activeItem && e.href === activeItem) {
                            nameClass = 'active'
                        }

                        return <Link to={e.href} className={nameClass} title={e.title} key={e.anchor}>{e.anchor}</Link>
                    })
                }
                <a className='icon' onClick={ handleClick } href="#" title="Menú">
                    <Icon icon="charm:menu-hamburger" />
                </a>
            </nav>
        </div>
    )
}

export default Navigation
