import * as React from "react"
import PropTypes from "prop-types"
import "./Tags.scss"

const Tags = ({ tags }) => {
    return(
        <div className="tags-container">
            {
                tags.map((tag, idx) => (<span className={`post-tag`} key={ `${tag}-${idx}` }>{ tag }</span>) )
            }
        </div>
    )
}

Tags.propTypes = {
    tags: PropTypes.array.isRequired
}

export default Tags
