import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Navigation from "../nav/Navigation"
import Image from "../image/Image";
import "./Header.scss"

const Header = ({ showH1 = false, activeItem }) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `
    )

    const siteTitle = site.siteMetadata?.title || ''

    return (
        <header className="top-header">
            <div className="logo-content">
                <Link to='/' title="Home">
                    {showH1 && <h1 style={{display: "none"}}>{siteTitle}</h1>}
                    <Image
                        className="logo"
                        title={ siteTitle }
                        alt={ siteTitle }
                        fileName="logo.png"
                        width="319"
                        height="84"
                    />
                </Link>
            </div>
            <Navigation activeItem={activeItem}/>
        </header>
    )
}


export default Header
