import * as React from "react"
import SocialNetworksWidget from "./widgets/socialNetworksWidget/SocialNetworksWidget"
import TagsCloud from "./widgets/tagsCloud/TagsCloud";
import Whoami from "./widgets/whoami/Whoami";
import "./Aside.scss"

const Aside = () => {
    return (
        <aside>
            <Whoami />
            <SocialNetworksWidget />
            <TagsCloud />
        </aside>
    )
}

export default Aside
