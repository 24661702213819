import * as React from "react"
import PropTypes from "prop-types"
import Header from "../header/Header"
import Aside from "../aside/Aside"
import Footer from "../footer/Footer"
import "./layout.css"
import "./MainLayout.scss"

const MainLayout = ({ showH1 = false, activeItem, children }) => {
    return (
        <>
            <Header
                showH1={ showH1 }
                activeItem={ activeItem }
            />
            <div className="main-layout">
                <main className="main-content">{children}</main>
                <Aside />
            </div>
            <section>
                <div className="skewed"></div>
            </section>
            <Footer />
        </>
    )
}


MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default MainLayout
