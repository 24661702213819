import * as React from "react"
import {Icon} from '@iconify/react'
import {useStaticQuery, graphql} from "gatsby"
import config from "../../config/config"

const SocialLinksList = () => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `
    )

    const siteTitle = site.siteMetadata?.title || ''
    const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)

    return (
        <ul>
            {
                config.social.map((e, idx) => (
                    <li key={`${e.name}-${idx}`}>
                        <a href={e.href} rel="nofollow noreferrer" target="_blank" title={`${capitalize(e.name)} de ${siteTitle}`}>
                            <Icon icon={e.icon || 'ant-design:question-circle-filled'}/>
                        </a>
                    </li>
                ))
            }
        </ul>
    )
}


export default SocialLinksList
