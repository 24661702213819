import * as React from "react"
import "./Whoami.scss"
import Image from "../../../image/Image";

const Whoami = () => {
    return(
        <div className="widget_whoami-container">
            <div className="widget_whoami-avatar">
                <Image
                    className="widget_whoami-avatar-image"
                    title="avatar"
                    alt="avatar"
                    fileName="avatar_boomer.png"
                    width="150"
                    height="150"
                />
            </div>
            <div className="widget_whoami-body">
                Hola mi nombre es <span className="whoami">@okboomersh</span> y soy un desarrollador web al que le gusta jugar a retos CTF, la música electrónica y pasar las horas en youtube.
            </div>
        </div>
    )
}

export default Whoami
